import { NOTICE_TYPE } from './notice-type.enum';

export const NOTICE_ICON_MAP = new Map<NOTICE_TYPE, string>([
  [NOTICE_TYPE.INFO, 'info_outlined'],
  [NOTICE_TYPE.WARNING, 'warning_amber'],
  [NOTICE_TYPE.ERROR, 'error_outlined'],
  [NOTICE_TYPE.HELP, 'help_outlined'],
  [NOTICE_TYPE.OTHER, 'help_outlined'],
  [NOTICE_TYPE.GENERAL, 'help'],
]);
